import React from 'react';


export interface SiteContextProps {
    isSideBarVisible: boolean
    setSideBarVisibility: (isVisible: boolean) => void;
}

const DefaultSiteContext: SiteContextProps = {
    isSideBarVisible: false,
    setSideBarVisibility: (isVisible: boolean) => { }
}


const SiteContext = React.createContext<SiteContextProps>(DefaultSiteContext);

class SiteProvider extends React.Component {
    state = {
        isSideBarVisible: false,
    }

    setSideBarVisibility = (isVisible: boolean) => {
        let isSideBarVisible = !this.state.isSideBarVisible
        this.setState({ isSideBarVisible });
    }
    
    render() {
        const { children } = this.props
        const { isSideBarVisible } = this.state
        return (
            <SiteContext.Provider
                value={{
                    isSideBarVisible,
                    setSideBarVisibility: this.setSideBarVisibility,
                }}
            >
                {children}
            </SiteContext.Provider>
        )
    }
}

export default SiteContext;
export { SiteProvider };