import * as React from "react";
import { SiteProvider } from './src/SiteContext';

export const wrapRootElement = ({ element }) => {
  return (
    <SiteProvider>
      {element}
    </SiteProvider>
  );

}
